.footerItems {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    padding-bottom: 20px;
    z-index: 400;
}

.footerButton {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.icon,
.icon>span>span {
    font-size: 24px !important;
}