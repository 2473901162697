.playerLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    gap: 16px
}

.playerLeftTitle {
    margin-bottom: 16px;
}

.playerLeftStats {
    width: 100%;
}

.playerLeftStatsCount {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: 100;
    opacity: 0.7;
}

.playerCenter {
    display: flex;
    flex-direction: column;
    padding: 32px;
    gap: 16px
}

.playerCenterStats {
    display: flex;
    gap: 8px;
    justify-content: space-between;
}

.playerCenterItem {
    display: flex;
    gap: 8px;
    flex-direction: column;
    align-items: center;
}

.playerCenterGames {
    display: flex;
    gap: 16px;
    flex-direction: column;
}

.playerCenterGamesItem {
    border: 1px solid var(--border);
    padding: 16px;
    border-radius: 8px;
    cursor: pointer;
}

.playerCenterGamesItem:hover {
    background-color: var(--layoutAccent);
}

.playerCenterGamesItemScore {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

}

.playerRight {
    display: flex;
    flex-direction: column;
    padding: 32px;
    gap: 16px
}

.playerCenterGamesItem {
    border: 1px solid var(--border);
    padding: 16px;
    border-radius: 8px;
}

.playerCenterGamesItemScore {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;

}

.gameContentName {
    display: flex;
    gap: 4px;
    flex-direction: column;
    align-content: center;
}

.gameContent {
    display: flex;
    align-items: center;
    justify-content: right;
}

.gameContentTitle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gameStatusTitle {
    display: flex;
    align-items: center;
    justify-content: right;
}

.teamTitle {
    display: flex;
    gap: 8px;
    align-items: center;
}

.gameStatus {
    display: flex;
    align-items: center;
    justify-content: right;
}

.playerCenterAwards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: center;
}

.award {
    padding: 8px 16px;
    border-radius: 8px;
    display: flex;
    gap: 8px;
    align-items: center;
    border: 2px solid rgba(138, 138, 138, 0.2);
}

.awardTitle {
    display: flex;
    flex-direction: column;
}

.playerBg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 48px;
    left: 0;
    filter: brightness(0.2);
}

.playerBgMobile {
    position: absolute;
    width: 100%;
    height: 100%;
    filter: brightness(0.2);
}