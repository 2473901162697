.gameInviteContainer {
    display: flex;
    gap: 8px;
    width: 100%;
    overflow: auto;
    padding: 8px;
}

.gameInviteItem {
    width: 300px;
    border-radius: 8px;
    padding: 8px;
}

.inviteContainer {
    border-radius: 8px;
    border: 1px solid black;
}

.timeTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px
}

.voteStatus {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px
}

.gamesItem {
    cursor: pointer;
    border-radius: 0;
    padding: 8px 16px;

}

.gamesItem:hover {
    background: var(--layoutAccent);
}

.gameStatusTitle {
    display: flex;
    align-items: center;
    justify-content: right;
}

.teamTitle {
    display: flex;
    gap: 8px;
    align-items: center;
}

.gameStatus {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 8px;
}

.gameContentName {
    display: flex;
    gap: 4px;
    flex-direction: column;
    align-content: center;
}

.gameContent {
    display: flex;
    align-items: center;
    justify-content: right;
}