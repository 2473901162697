.playerItem {
    cursor: pointer;
    display: flex;
    gap: 8px;
    align-items: center;
    width: fit-content;
}

.currentUserStyle {
    box-shadow: 0 0 4px 4px #1668dc;
    margin: 4px;
}

.currentUserTextStyle {
    color: #1668dc !important;
    font-weight: 900;
}

.liteData {
    z-index: 100;
    position: absolute;
    height: 68px;
    display: flex;
    gap: 16px;
    align-items: center;
    border-radius: 16px;
    background-color: rgba(0, 0, 0, 0.8);
}

.border {
    background-color: rgba(43, 65, 98, 0.1);
    background-image: linear-gradient(315deg, rgba(43, 65, 98, 0.1) 0%, rgba(18, 16, 14, 0.1) 74%);
}