.gamesContainer {
    height: 100%;
}

.gamesItems {
    height: calc(-36px + 100%);
    overflow-y: auto;
    overflow-x: hidden;
}

.gamesItem {
    cursor: pointer;
    border-radius: 0;
    padding: 8px 16px;

}

.gamesItem:hover {
    background: var(--layoutAccent);
}

.gameContentName {
    display: flex;
    gap: 4px;
    flex-direction: column;
    align-content: center;
}

.gameContent {
    display: flex;
    align-items: center;
    justify-content: right;
}

.gameContentTitle {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gameStatusTitle {
    display: flex;
    align-items: center;
    justify-content: right;
}

.teamTitle {
    display: flex;
    gap: 8px;
    align-items: center;
}

.gameStatus {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 8px;
}