html {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: "Fredoka", sans-serif;
}

code {
  font-family: "Fredoka", sans-serif;
}

.ant-table-cell-content {
  overflow: visible !important;
}