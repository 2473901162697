.storyContainer {
    display: flex;
    gap: 8px;
    overflow: auto;
}

.storyAvatarContainer {
    background-image: linear-gradient(#ba6fcdba, #1f5687c4);
    padding: 2px;
    border-radius: 1000px;
    cursor: pointer;
}

.storyMainContainer {
    background-image: linear-gradient(#0f792fc0, #093f0538);
    padding: 2px;
    border-radius: 1000px;
    cursor: pointer;
}

.storyMainContainer>span>img {
    object-fit: contain;
}

.storyText {
    background-image: linear-gradient(#6fcd8b, #28871f);
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 8px;
}

.storyMusic {
    position: absolute;
    top: 36px;
    z-index: 10;
    padding: 16px;
    display: flex;
    gap: 4px;
    align-items: center;
    opacity: 0.8;
}

.storyTextInner {
    padding: 64px 32px;
    width: calc(-64px + 100%);
    height: calc(-128px + 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.storyLink {
    position: absolute;
    bottom: 0;
    padding: 32px;
    width: calc(-64px + 100%);
    text-align: center;
    z-index: 10;
}

.storyAvatarContainerWatched {
    background: rgb(51, 51, 51);
    padding: 1px;
    border-radius: 1000px;
    cursor: pointer;
}

.storyAvatar {
    position: absolute;
    top: 20px;
    left: 16px;
    z-index: 20;
    display: flex;
    gap: 8px;
    align-items: center;
    width: 100%;
}

.storyDate {
    position: absolute;
    top: 24px;
    right: 36px;
    z-index: 20;
}

.storyActions {
    position: absolute;
    bottom: 16px;
    left: 16px;
    z-index: 20;
}

.storyLike {
    position: absolute;
    bottom: 16px;
    right: 16px;
    z-index: 20;
}

.storyTimeline {
    width: calc(-32px + 100%);
    height: 4px;
    display: flex;
    gap: 4px;
    padding: 8px 16px;
    position: absolute;
    top: 0
}

.storyTimelineItem {
    background-color: gray;
    width: 100% !important;
    height: 100% !important;
    border-radius: 8px;
}

.storyTimelineItemLoading {
    width: 100% !important;
    height: 100% !important;
    border-radius: 8px;
}

.storyReplay {
    width: 100%;
    height: 100%;
}

.storyReplayOverlay {
    width: 100%;
    height: 60px;
    position: absolute;
    top: 0;
    background: linear-gradient(180deg, rgba(8, 8, 8, 0.685) 0%, transparent 100%) !important;
}

.storyReplayLeft {
    width: 50%;
    position: absolute;
    top: 0;
    height: 100%;
}

.storyReplayRight {
    width: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
}

.storyLoading {
    top: 0;
    background: #141414 !important;
    position: absolute;
}