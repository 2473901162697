#root {
  overflow: hidden;
}

body {
  font-family: "Fredoka", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.layout {
  background-color: #202020;
}

.app {
  height: 100vh;
}

.content-mobile {
  overflow: auto;
  height: calc(-42px + 100vh);
}

.content {
  height: calc(-106px + 100vh);
  overflow: auto;
  overflow-x: hidden;
  width: calc(-64px + 100%);
  padding: 32px;
}

.content:has(.content-without-padding) {
  width: 100%;
  padding: 0;
  height: calc(-42px + 100vh);
}

.right-align {
  text-align: right;
}

.center-align {
  text-align: center;
}

.subtitle {
  color: var(--textSecondary);
  font-size: 12px;
}

.app {
  background-color: var(--layout);
  color: var(--text);
}

.ant-card-body {
  height: calc(-56px + 100%);
}

.ant-table-wrapper,
.ant-spin-nested-loading,
.ant-spin-container,
.ant-table,
.ant-table-container {
  height: 100%;
}

.ant-table-body {
  height: calc(-37px + 100%);
}

.ant-table-body table {
  height: 100%;
}

.ant-table-content {
  overflow: auto;
}

.ant-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.content-loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-loading-in {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-btn-dashed {
  box-shadow: none;
}

.ant-tag {
  margin-inline-end: 0
}

.ant-typography {
  margin: 0 !important
}

.warning-tags {
  position: fixed;
  bottom: 32px;
  right: 32px;
  padding: 8px;
  border-radius: 8px;
  background-color: var(--layoutAccent);
}

.ant-slider-horizontal.ant-slider-with-marks {
  margin-bottom: 0;
}

.main-layout {
  height: 100vh;
}

.ant-card-body {
  height: 100%;
  padding: 0 !important;
}

.ant-carousel,
.slick-list {
  height: 100%;
}

ul.slick-dots.slick-dots-bottom {
  position: absolute;
  bottom: 0
}

.slick-dots button {
  background-color: hsla(0, 0%, 85%, 1) !important;
  width: 8px !important;
  height: 8px !important;
  border-radius: 100% !important;
}

.slick-dots li {
  width: 8px !important;
}

.slick-dots .slick-active {
  width: 8px !important;
}

.slick-track {
  height: 100%;
}

.slick-slide,
.slick-slide>div {
  height: 100%;
}

.ant-typography {
  margin-bottom: 0 !important;
}

.modal-without-background .ant-modal-content {
  background-color: transparent;
  padding: 0;
  max-width: unset;
  margin: unset;
}

.mobile-modal-without-background .ant-modal-content {
  background-color: transparent;
  padding: 0;
  max-width: unset;
  margin: unset;
}

.mobile-modal-without-background .ant-modal {
  margin: 0 !important;
}

.mobile-modal-without-background {
  overflow: hidden !important;
}

.ant-slider-mark {
  top: 8px !important;
}

.mobile-modal-without-background .ant-modal .ant-modal-close,
.modal-without-background .ant-modal .ant-modal-close {
  top: 22px;
  right: 8px;
}

.modal-without-background .ant-modal-body>div>div:first-child,
.mobile-modal-without-background .ant-modal-body>div>div:first-child {
  z-index: 20;
}

.modal-without-background .ant-modal-body>div,
.mobile-modal-without-background .ant-modal-body>div {
  background-color: transparent;
}

.ant-modal-root .ant-modal {
  max-width: 100%;
}

.animated {
  height: 100%;
}

.ant-btn-lg .ant-btn-icon {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  width: 8px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 16px !important;
}

::-webkit-scrollbar-corner {
  display: none;
}

div {
  scrollbar-color: unset !important;
}

.ant-table-wrapper .ant-table {
  border-radius: 16px;
}

.ant-tabs-nav {
  margin: 0 !important;
}

.dark a {
  color: rgba(255, 255, 255, 0.85);
}

.light a {
  color: rgba(0, 0, 0, 0.85);
}

.apexcharts-toolbar {
  display: none !important;
}

.leaflet-bottom.leaflet-right {
  display: none;
}

.leaflet-div-icon {
  background-color: transparent !important;
  border: none !important;
  border-radius: 100%;
}

.leaflet-div-icon-user {
  background-color: transparent !important;
  border: 2px solid red !important;
  border-radius: 100%;
}

.ant-menu {
  background-color: transparent !important;
}

.bgCity {
  background-image: url('/public/assets/shop/city.gif');
  background-size: cover;
}

.bgSolar {
  background-image: url('/public/assets/shop/solarsystem.gif');
  background-size: cover;
}

.bgSun {
  background-image: url('/public/assets/shop/sunandsea.gif');
  background-size: cover;
}

.bgDarkSun {
  background-image: url('/public/assets/shop/darksun.gif');
  background-size: cover;
}

.bgSolar2 {
  background-image: url('/public/assets/shop/solar2.gif');
  background-size: cover;
}

.bgTree {
  background-image: url('/public/assets/shop/tree.gif');
  background-size: cover;
}

.brLightning {
  background-image: url('/public/assets/shop/lightning.gif') !important;
  background-size: cover;
}

.brRain {
  background-image: url('/public/assets/shop/rain.gif') !important;
  background-size: cover;
}

.brSphere {
  background-image: url('/public/assets/shop/sphere.gif') !important;
  background-size: cover;
}

.brTriangles {
  background-image: url('/public/assets/shop/triangles.gif') !important;
  background-size: cover;
}

.brCircle {
  background-image: url('/public/assets/shop/circle.gif') !important;
  background-size: cover;
}

.ant-card {
  backdrop-filter: blur(16px) saturate(180%);
  border: 1px solid rgba(255, 255, 255, 0.125);
}

.ant-popover-inner,
.ant-modal-content {
  background-color: rgba(37, 37, 37, 0.75) !important;
  backdrop-filter: blur(16px) saturate(180%);
}

.ant-modal-header {
  background-color: transparent !important;
}

.ant-list-item:hover {
  background-color: #3f3f3f24;
  cursor: pointer;
}

.left-align {
  text-align: left !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: end;
}

.slick-slide>div>div {
  height: 100%;
}