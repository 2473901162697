.slider {
    position: absolute;
    display: flex;
    width: calc(-32px + 100%);
    gap: 16px;
    bottom: 16px;
    left: 16px;
    background-color: rgba(17, 25, 40, 0.75);
    padding: 8px;
    border-radius: 8px;
    backdrop-filter: blur(16px) saturate(180%);
}

.sliderItem {
    width: calc(-48px + 100%);
}

.viewerContainer {
    width: 100%;
    height: 100%;
}

.replayViewer {
    width: 100%;
    border-radius: 32px;
    position: absolute;
    left: 0;
    top: 48px
}

.replayCanvas {
    width: 100%;
    height: 100%;
    display: block;
    background-color: black;
}

.playerList {
    position: absolute;
    top: 58px;
    left: 16px;
}

.chatMessages {
    position: absolute;
    bottom: 82px;
    left: 16px;
}

.map {
    position: absolute;
    top: 58px;
    right: 16px;
}

.highlights {
    position: absolute;
    bottom: 82px;
    right: 16px;
}

.reportOverlay {
    position: absolute;
    top: 58px;
    right: 16px;
    width: 200px;
    background-color: #14141452;
    padding: 8px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.score {
    position: absolute;
    left: calc(-93px + 50%);
    display: flex;
    width: 186px;
    gap: 16px;
    align-items: center;
    justify-content: center;
    background-color: #141414;
    padding: 8px;
    border-radius: 8px;
}

.mapContent {
    height: 305px;
    width: 150px;
    border-radius: 38px;
    background-color: rgba(128, 128, 128, 0.247);
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.skater {
    position: absolute;
    background-color: red;
    border-radius: 100%;
    width: 8px;
    height: 8px;
}

.skaterName {
    position: absolute;
    text-align: center;
    font-size: 10px;
    width: 0;
    z-index: 1;
}

.skaterName>span {
    left: -50%;
}

.puck {
    position: absolute;
    background-color: black;
    border-radius: 100%;
    width: 6px;
    height: 6px;
}

.overlayWithout>div>div {
    background-color: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
}

.highlightList {
    max-height: 400px;
    overflow: auto;
    gap: 8px;
    display: flex;
    flex-direction: column;
}

.highlightListItem {
    display: flex;
    gap: 8px;
    cursor: pointer;
    justify-content: space-between
}

.objectsItem {
    padding: 4px;
    border-radius: 8px;
    cursor: pointer;
}

.objectsItemSelected {
    color: rgb(16, 142, 233)
}