.headerContainerLogin {
    display: flex;
    gap: 8px;
    align-items: center;
}

.patrolHeader {
    width: 300px;
}

.rankedLogo {
    background-color: #179c75;
    height: 24px;
    display: flex;
    padding: 2px 4px;
    align-items: center;
    font-weight: 900;
    color: white;
    box-shadow: 4px 4px 0px 0 #179c7428;
    border-radius: 16px;
}

.teamsLogo {
    background-color: #606392;
    height: 24px;
    display: flex;
    padding: 2px 4px;
    align-items: center;
    font-weight: 900;
    color: white;
    box-shadow: 4px 4px 0px 0 #6063927c;
}

.filteredLogo {
    filter: grayscale();
}

.contactCard {
    width: 86px;
    height: 150px;
    border-radius: 16px;
    padding: 8px;
    position: relative;
    cursor: pointer;
}

.contactCard:hover {
    background-color: red;
}

.contractContainer {
    width: 352px;
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    list-style: none;
    height: 400px;
    overflow: auto;
    padding: 4px;
}

.contactCardText {
    gap: 8px;
    font-size: 12px;
    align-items: center;
}

.contactCardPoints {
    display: flex;
    gap: 8px;
    align-items: center;
    width: calc(-16px + 100%);
    position: absolute;
    bottom: 8px;
}

.contactCardDate {
    display: flex;
    gap: 8px;
    align-items: center;
    width: calc(-16px + 100%);
    position: absolute;
    bottom: 8px;
}